import React from "react";
import { Box, Grid, Hidden, Button } from "@material-ui/core";
import WrapTypo from "../components/WrapTypo";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Head from "../components/head";

function Doshi() {
  const data = useStaticQuery(graphql`
    query {
      handshake: file(relativePath: { eq: "handshake.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      form: file(relativePath: { eq: "form.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Box width="100%">
      <Head />
      <Box width="90%" margin="auto" mt={5}>
        <Hidden smUp>
          <WrapTypo style={{ writingMode: "vertical-rl" }}>同志の募集</WrapTypo>
        </Hidden>
        <Grid container margin="auto">
          <Grid item xs={12} md={5}>
            <Img fluid={data.handshake.childImageSharp.fluid} />
          </Grid>
          <Grid item xs={4} md={1}>
            <Box>
              <WrapTypo fs="20px" color="#4C4C4C">
                採用情報
              </WrapTypo>
            </Box>
            <Box mt={[0, 0, 1]}>
              <WrapTypo color="#4C4C4C">仕事内容 :</WrapTypo>
              <WrapTypo color="#4C4C4C" lh={4}>
                勤務形態 :
              </WrapTypo>
              <WrapTypo color="#4C4C4C" lh={4}>
                給与 ：
              </WrapTypo>
              <WrapTypo color="#4C4C4C" lh={4}>
                福利厚生 :
              </WrapTypo>
              <WrapTypo color="#4C4C4C" lh={4}>
                休日 :
              </WrapTypo>
              <WrapTypo color="#4C4C4C" lh={4}>
                免許 :
              </WrapTypo>
            </Box>
          </Grid>
          <Grid item xs={8} md={5}>
            <Box mt={[3, 4, 5]}>
              <WrapTypo color="#4C4C4C">
                現場作業員,施工管理 (性別は問いません。)
              </WrapTypo>
              <WrapTypo color="#4C4C4C" lh={4}>
                正社員
              </WrapTypo>
              <WrapTypo color="#4C4C4C" lh={4}>
                経験・資格・年齢を考慮の上決定いたします。
              </WrapTypo>
              <WrapTypo color="#4C4C4C" lh={4}>
                社会保険（健康保険・厚生年金・雇用保険・労災保険）
              </WrapTypo>
              <WrapTypo color="#4C4C4C" lh={4}>
                年末年始・お盆・5月連休（GW）・その他会社指定日
              </WrapTypo>
              <WrapTypo color="#4C4C4C" lh={4}>
                普通免許
              </WrapTypo>
            </Box>
          </Grid>
          <Grid item md={1}>
            <Hidden xsDown>
              <WrapTypo
                fw="700"
                fs="24px"
                style={{ writingMode: "vertical-rl" }}
              >
                同志の募集
              </WrapTypo>
            </Hidden>
          </Grid>
        </Grid>
        <Box textAlign="center" mb={3}>
          <WrapTypo color="#4C4C4C">
            先ずは下記フォームよりお気軽にお問い合わせください。
          </WrapTypo>
          <WrapTypo color="#4C4C4C">
            ご質問は面接時に全てお答え致します。
          </WrapTypo>
        </Box>
        <Box textAlign="center">
          <Button
            variant="contained"
            color="#707070"
            disableElevation
            style={{ height: "60px", width: "70%", spacing: "20px" }}
          >
            <WrapTypo color="#4C4C4C">面接応募フォームの確認をする</WrapTypo>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default Doshi;
